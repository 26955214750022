//const URL = `http://localhost/teresa/liminal/deploy/api`;
const URL = `https://backend.liminalroutes.net/api`;

const fetchPage = async path => {
    console.log(path)
    const response = await fetch(`${URL}${path}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

export { fetchPage };